import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Carousel from 'react-bootstrap/Carousel'
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons'

import Banner from './Banner'
import Navbar from './Navbar'

import { allImages } from '../constants'

import ArborSand from '../Assets/ArborSand.jpg'
import ArborSand2 from '../Assets/ArborSand2.jpg'
import Degroff from '../Assets/Degroff.jpg'
import Degroff2 from '../Assets/degroff2.jpg'
import ShotGlass from '../Assets/ShotGlass.jpg'
import Tequila from '../Assets/Tequila.jpg'
import Tequila2 from '../Assets/tequila2.jpg'
import Tequila3 from '../Assets/tequila3.jpg'
import Tequila4 from '../Assets/tequila4.jpg'
import BriArbor from '../Assets/BriArbor.jpg'
import BriArbor2 from '../Assets/BriArbor2.jpg'
import BriArborOutside from '../Assets/BriArborOutside.jpg'
import BriChrisArbor from '../Assets/BriChrisArbor.jpg'
import tomFrankDonut from '../Assets/tomFrankDonut.jpg'
import Sterno3 from '../Assets/Sterno3.jpg'
import BasketballDonut from '../Assets/BasketballDonut.jpg'
import AcrylicDonut from '../Assets/AcrylicDonut.jpg'
import AquariumHedge from '../Assets/AquariumHedge.JPG'
import BabyBackdrop from '../Assets/BabyBackdrop.JPG'
import Cakepop2 from '../Assets/Cakepop22.jpg'
import Cakepop from '../Assets/CakePop.JPG'
import CaputoCornhole from '../Assets/CaputoCornhole.JPG'
import ChuppahOutside from '../Assets/ChuppahOutside.JPG'
import ConnellyWelcome from '../Assets/ConnellyWelcome.JPG'
import DessertTier from '../Assets/DessertTier.JPG'
import Doors from '../Assets/Doors.jpg'
import DrinkSign from '../Assets/Drink_Sign.jpg'
import EleniPallet from '../Assets/EleniPallet.jpg'
import FruitTier from '../Assets/FruitTier.JPG'
import Groovy from '../Assets/Groovy.JPG'
import Border from '../Assets/border.jpg'
import LogoUpdate from '../Assets/LogoUpdate.jpg'
import Promotion2 from '../Assets/promotions2.jpg'
import MiniChampagne from '../Assets/MiniChampagne.jpg'
import NicoleChampagne from '../Assets/Nicole_Champagne.jpg'
import RimmerWelcome from '../Assets/RimmerWelcome.jpg'
import SeatingChart from '../Assets/Seating_Chart.jpg'
import Slainte from '../Assets/Slainte.jpg'
import SlatWall from '../Assets/SlatWall.jpg'
import TT from '../Assets/T & T.jpg'
import TableNumber10 from '../Assets/Table Number.JPG'
import TyeDye from '../Assets/Tye Dye.JPG'
import WeirdDonut from '../Assets/Weird Donut.JPG'
import WelcomeTosan from '../Assets/Welcome Tosan.JPG'
import WelcomeCocktail from '../Assets/Welcome_Cocktail.jpg'
import Donuts from '../Assets/Donuts.jpg'
import WeddingChampagne from '../Assets/WeddingChampagne.jpg'
import danAndrewWelcome from '../Assets/danAndrewWelcome.jpg'
import NewBeginningWelcomeSign from '../Assets/NewBeginningWelcomeSign.jpg'
import BriWelcome from '../Assets/BriWelcome.jpg'
import BestWeddingEverSign from '../Assets/BestWeddingEverSign.jpg'
import pictureOfAPicture from '../Assets/pictureOfAPicture.jpg'
import Cornhole from '../Assets/Cornhole.jpg'
import CornholePlay from '../Assets/CornholePlay.jpg'
import jenga from '../Assets/jenga.jpg'
import JengaDay from '../Assets/JengaDay.jpg'
import JengaNight from '../Assets/JengaNight.jpg'
import DonutMind from '../Assets/DonutMind.jpg'
import TakeAPic from '../Assets/TakeAPic.jpg'
import Posivibes from '../Assets/Posivibes.jpg'
import WalkSign from '../Assets/WalkSign.jpg'
import ChampagneGlassTags from '../Assets/ChampagneGlassTags.jpg'
import polaroids from '../Assets/polaroids.jpg'
import mrmrssign from '../Assets/mrmrssign.png'
import weddingrules from '../Assets/weddingrules.jpg'
import kaiasign from '../Assets/kaiasign.jpg'
import Centerpieces from '../Assets/Centerpieces.jpg'
import demiDonut from '../Assets/demiDonut.jpg'
import SeatingArrangements from '../Assets/SeatingArrangements.jpg'
import frankEleniWelcome from '../Assets/frankEleniWelcome.jpg'
import welcomeSetUp from '../Assets/welcomeSetUp.jpg'
import eleniFrankArbor from '../Assets/eleniFrankArbor.jpg'
import arborChairs from '../Assets/arborChairs.jpg'
import ArborOutside from '../Assets/ArborOutside.jpg'
import donutAngleFull from '../Assets/donutAngleFull.jpg'
import lightBulb from '../Assets/lightBulb.jpg'
import champagneGlasses from '../Assets/champagneGlasses.jpg'
import takeAPicture from '../Assets/takeAPicture.jpg'
import Chuppah from '../Assets/Chuppah.jpg'


const images = {
    'ArborSand': ArborSand,
    'ArborSand2': ArborSand2,
    'Degroff': Degroff,
    'Degroff2': Degroff2,
    'ShotGlass': ShotGlass,
    'Chuppah': Chuppah,
    'Tequila': Tequila,
    'Tequila2': Tequila2,
    'Tequila3': Tequila3,
    'Tequila4': Tequila4,
    'BriArbor': BriArbor,
    'BriArbor2': BriArbor2,
    'AquariumHedge': AquariumHedge,
    'BabyBackdrop': BabyBackdrop,
    'Cakepop2': Cakepop2,
    'Cakepop': Cakepop,
    'CaputoCornhole': CaputoCornhole,
    'ChuppahOutside': ChuppahOutside,
    'ConnellyWelcome': ConnellyWelcome,
    'DessertTier': DessertTier,
    'Doors': Doors,
    'DrinkSign': DrinkSign,
    'EleniPallet': EleniPallet,
    'FruitTier': FruitTier,
    'Groovy': Groovy,
    'Border': Border,
    'LogoUpdate': LogoUpdate,
    'Promotion2': Promotion2,
    'MiniChampagne': MiniChampagne,
    'NicoleChampagne': NicoleChampagne,
    'RimmerWelcome': RimmerWelcome,
    'SeatingChart': SeatingChart,
    'Slainte': Slainte,
    'SlatWall': SlatWall,
    'TT': TT,
    'TableNumber10': TableNumber10,
    'TyeDye': TyeDye,
    'WeirdDonut': WeirdDonut,
    'WelcomeTosan': WelcomeTosan,
    'WelcomeCocktail': WelcomeCocktail,
    'BriArborOutside': BriArborOutside,
    'BriChrisArbor': BriChrisArbor,
    'tomFrankDonut': tomFrankDonut,
    'Sterno3': Sterno3,
    'BasketballDonut': BasketballDonut,
    'AcrylicDonut': AcrylicDonut,
    'Donuts': Donuts,
    'WeddingChampagne': WeddingChampagne,
    'danAndrewWelcome': danAndrewWelcome,
    'NewBeginningWelcomeSign': NewBeginningWelcomeSign,
    'BriWelcome': BriWelcome,
    'BestWeddingEverSign': BestWeddingEverSign,
    'pictureOfAPicture': pictureOfAPicture,
    'Cornhole': Cornhole,
    'CornholePlay': CornholePlay,
    'jenga': jenga,
    'JengaDay': JengaDay,
    'JengaNight': JengaNight,
    'DonutMind': DonutMind,
    'TakeAPic': TakeAPic,
    'Posivibes': Posivibes,
    'WalkSign': WalkSign,
    'ChampagneGlassTags': ChampagneGlassTags,
    'polaroids': polaroids,
    'mrmrssign': mrmrssign,
    'weddingrules': weddingrules,
    'kaiasign': kaiasign,
    'Centerpieces': Centerpieces,
    'demiDonut': demiDonut,
    'SeatingArrangements': SeatingArrangements,
    'frankEleniWelcome': frankEleniWelcome,
    'welcomeSetUp': welcomeSetUp,
    'eleniFrankArbor': eleniFrankArbor,
    'arborChairs': arborChairs,
    'ArborOutside': ArborOutside,
    'donutAngleFull': donutAngleFull,
    'lightBulb': lightBulb,
    'champagneGlasses': champagneGlasses,
    'takeAPicture': takeAPicture
}


const Creations = () => {
    const [selectedImage, setSelectedImage] = useState(null)
    const [index, setIndex] = useState(null)

    const getCarouselItems = () => {
        return (
            allImages.map((img, i) => {
                console.log(img)
                return (
                    <Carousel.Item key={i} className='carousel-item'>
                        <img
                            className='carousel-image'
                            alt={img.title}
                            src={images[img.name]}
                        />
                        <Carousel.Caption>
                            <h3>{img.title}</h3>
                        </Carousel.Caption>
                    </Carousel.Item>
                )
            })
        )
    }

    const openCarousel = () => {
        return (
            <Carousel 
                className='zoom-image-container'
                interval={null}
                prevIcon={<ChevronLeft size={50} aria-label='previous'/>}
                nextIcon={<ChevronRight size={50} aria-label='next'/>}
                defaultActiveIndex={index}
            >
                {getCarouselItems()}
            </Carousel>
        )
    }

    const handleCarousel = (value, index) => {
        value ? document.body.style.overflow = 'hidden' :
            document.body.style.overflow = 'unset'
        
        setSelectedImage(value)
        setIndex(index)
    }

    const getSelectedImage = () => {
        return ( 
            <div className="zoom">
                <div className='zoom-img-button'>
                    <button className="close-carousel" onClick={() => handleCarousel(null)}>&#x2613;</button>
                    { openCarousel() }
                </div>
            </div>
        )
    }

    const mapImages = () => {
        return allImages.map((img, i) => 
            <div className="creations-grid-card" key={i} onClick={() => handleCarousel(img, i)}>
                <div className="creations-grid-card-text">
                    <h3>{img.title}</h3>
                    <p>{img.description}</p>
                </div>
                <img
                    className='zoom-image'
                    alt={img.title}
                    src={images[img.name]}
                />
            </div>
        )
    }

    return (
        <div className="component" id="creation-component">
            <Navbar />
            <Banner />
            <div className='header-container' >
                <h1 className='component-header'>Creations</h1>
                <div className='link-to-contact'>
                    <p>Don't see something you want? We can make it!&nbsp;</p>
                    <Link className='creations-link' to={{pathname: '/contact'}}>
                        Contact Us!
                    </Link>
                </div>
            </div>
            {selectedImage && getSelectedImage()}
            <div className="creations-grid-container">
                {mapImages()}
            </div>
        </div>
    )
}

export default Creations