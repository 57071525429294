// packages images

export const packageOneImages = [
    { image: 'ArborOutside', title: 'Arbor' },
    { image: 'BriWelcome', title: 'Welcome Sign' },
    { image: 'Sterno3', title: 'Donut Wall' },
    { image: 'WeddingChampagne', title: 'Champagne Wall' },
    { image: 'SheetBackdrop', title: 'Picture Backdrop' },
    { image: 'JengaDay', title: 'Jenga' },
    { image: 'CornholePlay', title: 'Cornhole' },
    { image: 'TakeAPic', title: 'Picture Backdrop Sign' },
    { image: 'weddingrules', title: 'Custom Sign' },
    { image: 'kaiasign', title: 'Custom Signature Drinks Sign' },
    { image: 'WalkSign', title: 'Custom Sign' },
    { image: 'Centerpieces', title: 'Centerpieces' },
    { image: 'mrmrssign', title: 'Mr & Mrs Chair Signs' }
]

// all images (creations, home page)

export const allImages = [
    
    {
        name: 'ArborSand', title: 'Arbor',
        description: ""
    },
    {
        name: 'Promotion2', title: '',
        description: ""
    },
    {
        name: 'Degroff', title: 'Shot Glass Wall',
        description: "6 Feet Tall x 4 Feet Wide. 7 Acrylic Shelves that Holds 126 Shot Glasses. Comes with Changing LED Light Strips and Custom Lettering on Top."
    },
    {
        name: 'Tequila', title: 'Shot Glass Wall',
        description: "6 Feet Tall x 4 Feet Wide. 7 Acrylic Shelves that Holds 126 Shot Glasses. Comes with Changing LED Light Strips and Custom Lettering on Top."
    }, 
    {
        name: 'Sterno3', title: 'Donut Wall',
        description: "6 Feet Tall x 3 Feet Wide x 2.5 Feet Deep. Holds between 32-96 donuts. Has 2 shelves and utensil holder. Comes with 'Donut Mind If I Do' sign."
    },
    {
        name: 'SlatWall', title: 'Photo Backdrop',
        description: ""
    },
    {
        name: 'CaputoCornhole', title: 'Custom Cornhole',
        description: ""
    },
    {
        name: 'Groovy', title: 'Champagne Wall',
        description: ""
    },
    {
        name: 'ConnellyWelcome', title: 'Welcome Sign',
        description: ""
    },
    {
        name: 'WeirdDonut', title: 'Mini Donut Wall',
        description: ""
    },
    {
        name: 'AquariumHedge', title: 'Hedge Wall/Champagne Wall',
        description: ""
    },
    {
        name: 'DessertTier', title: 'Dessert Tier',
        description: ""
    },
    {
        name: 'MiniChampagne', title: 'Mini Champagne Wall',
        description: ""
    },
    {
        name: 'NicoleChampagne', title: 'Champagne Wall',
        description: ""
    },
    {
        name: 'BasketballDonut', title: 'Donut Wall',
        description: "6 Feet Tall x 3 Feet Wide x 2.5 Feet Deep. Holds between 32-96 donuts. Has 2 shelves and utensil holder. Comes with 'Donut Mind If I Do' sign."
    },
    {
        name: 'Cakepop2', title: 'Cake Pop Wall',
        description: ""
    },
    {
        name: 'Slainte', title: 'Champagne Wall',
        description: ""
    },
    {
        name: 'Doors', title: 'Aisle Doors',
        description: ""
    },
    {
        name: 'RimmerWelcome', title: 'Welcome Sign',
        description: ""
    },
    {
        name: 'WelcomeTosan', title: 'Welcome Sign',
        description: ""
    },
    {
        name: 'DrinkSign', title: 'Custom Drink Sign',
        description: ""
    },
    {
        name: 'SeatingChart', title: 'Seating Chart',
        description: ""
    },
    {
        name: 'EleniPallet', title: 'Picture Pallet',
        description: ""
    },
    {
        name: 'FruitTier', title: 'Fruit Tier',
        description: ""
    },
    {
        name: 'TableNumber10', title: 'Table Number',
        description: ""
    },
    {
        name: 'TT', title: 'Custom Name Sign',
        description: ""
    },
    {
        name: 'TyeDye', title: 'Photo Backdrop',
        description: ""
    },
    {
        name: 'Tequila2', title: 'Shot Glass Wall',
        description: "6 Feet Tall x 4 Feet Wide. 7 Acrylic Shelves that Holds 126 Shot Glasses. Comes with Changing LED Light Strips and Custom Lettering on Top."
    },
    {
        name: 'ShotGlass', title: 'Shot Glass Wall',
        description: "6 Feet Tall x 4 Feet Wide. 7 Acrylic Shelves that Holds 126 Shot Glasses. Comes with Changing LED Light Strips and Custom Lettering on Top."
    },
    {
        name: 'Degroff2', title: 'Shot Glass Wall',
        description: "6 Feet Tall x 4 Feet Wide. 7 Acrylic Shelves that Holds 126 Shot Glasses. Comes with Changing LED Light Strips and Custom Lettering on Top."
    },
    {
        name: 'Tequila3', title: 'Shot Glass Wall',
        description: "6 Feet Tall x 4 Feet Wide. 7 Acrylic Shelves that Holds 126 Shot Glasses. Comes with Changing LED Light Strips and Custom Lettering on Top."
    },
    {
        name: 'ArborSand2', title: 'Arbor',
        description: ""
    },
    {
        name: 'Tequila4', title: 'Shot Glass Wall',
        description: "6 Feet Tall x 4 Feet Wide. 7 Acrylic Shelves that Holds 126 Shot Glasses. Comes with Changing LED Light Strips and Custom Lettering on Top."
    },
    {
        name: 'BriArbor', title: 'Triangular Arbor',
        description: "9 Feet Tall x 8.5 Feet Wide with 4.5 foot long base. Includes eye hooks on the back for flowers."
    },
    {
        name: 'BriArbor2', title: 'Triangular Arbor',
        description: "9 Feet Tall x 8.5 Feet Wide with 4.5 foot long base. Includes eye hooks on the back for flowers."
    },
    {
        name: 'BriChrisArbor', title: 'Triangular Arbor',
        description: "9 Feet Tall x 8.5 Feet Wide with 4.5 foot long base. Includes eye hooks on the back for flowers."
    },
    {
        name: 'BriArborOutside', title: 'Triangular Arbor',
        description: "9 Feet Tall x 8.5 Feet Wide with 4.5 foot long base. Includes eye hooks on the back for flowers."
    },
    {
        name: 'Cakepop', title: 'Cake Pop Wall',
        description: ""
    },
    {
        name: 'Chuppah', title: 'Chuppah',
        description: "8 Feet Tall x 7 Feet Wide x 7 Feet Deep."
    },
    {
        name: 'ChuppahOutside', title: 'Chuppah',
        description: "8 Feet Tall x 7 Feet Wide x 7 Feet Deep."
    },
    {
        name: 'AcrylicDonut', title: 'Mini Donut Wall',
        description: ""
    },
    {
        name: 'tomFrankDonut', title: 'Donut Wall',
        description: "6 Feet Tall x 3 Feet Wide x 2.5 Feet Deep. Holds between 32-96 donuts. Has 2 shelves and utensil holder. Comes with 'Donut Mind If I Do' sign."
    },
    {
        name: 'Donuts', title: 'Donut Wall',
        description: "6 Feet Tall x 3 Feet Wide x 2.5 Feet Deep. Holds between 32-96 donuts. Has 2 shelves and utensil holder. Comes with 'Donut Mind If I Do' sign."
    },
    {
        name: 'WelcomeCocktail', title: 'Welcome Sign',
        description: ""
    },
    {
        name: 'WeddingChampagne', title: 'Champagne Wall',
        description: "7 Feet Tall x 3 Feet Wide x 2 Feet Deep. Holds 60-120 champagne flutes. Comes with 'Cheers!' sign."
    },
    {
        name: 'BabyBackdrop', title: 'Photo Backdrop',
        description: ""
    },  
    {
        name: 'danAndrewWelcome', title: 'Welcome Sign',
        description: "3 Feet Wide x 2.5 Feet Tall with Stand"
    },
    {
        name: 'NewBeginningWelcomeSign', title: 'Welcome Sign',
        description: "3 Feet Wide x 2.5 Feet Tall with Stand"
    },
    {
        name: 'BriWelcome', title: 'Welcome Sign',
        description: "3 Feet Wide x 2.5 Feet Tall with Stand"
    },
    {
        name: 'BestWeddingEverSign', title: 'Welcome Sign',
        description: "3 Feet Wide x 2.5 Feet Tall with Stand"
    },
    {
        name: 'pictureOfAPicture', title: 'Picture Backdrop',
        description: "6.5 Feet Tall x 7 Feet Wide x 1.5 Feet Deep. Comes with 'Take A Picture It'll Last Longer' sign."
    },
    {
        name: 'Cornhole', title: 'Custom Cornhole',
        description: "Custom Cornhole with Wheels, Cup Holders, Scorekeeper, LED Lights, and Bean Bags"
    },
    {
        name: 'CornholePlay', title: 'Custom Cornhole',
        description: "Custom Cornhole with Wheels, Cup Holders, Scorekeeper, LED Lights, and Bean Bags"
    }, 
    {
        name: 'jenga', title: 'Plummeting Prisms',
        description: "Blocks are 12 Inches Long x 4 Inches Wide x 2 Inches Tall. The full stack is 2.5 feet tall."
    },
    {
        name: 'JengaDay', title: 'Plummeting Prisms',
        description: "Blocks are 12 Inches Long x 4 Inches Wide x 2 Inches Tall. The full stack is 2.5 feet tall."
    },
    {
        name: 'JengaNight', title: 'Plummeting Prisms',
        description: "Blocks are 12 Inches Long x 4 Inches Wide x 2 Inches Tall. The full stack is 2.5 feet tall."
    },
    {
        name: 'DonutMind', title: 'Custom Sign',
        description: ""
    },
    {
        name: 'TakeAPic', title: 'Custom Sign',
        description: ""
    },
    {
        name: 'Posivibes', title: 'Custom Sign',
        description: ""
    },
    {
        name: 'WalkSign', title: 'Custom Sign',
        description: ""
    },
    {
        name: 'ChampagneGlassTags', title: '',
        description: ""
    },
    {
        name: 'polaroids', title: 'Picture Pallet',
        description: "6.5 Feet Tall x 7 Feet Wide x 1.5 Feet Deep. Comes with mini clothespins, battery powered LED lights, and 'Take a Picture Let's Fill This Pallet With Memories' sign."
    },
    {
        name: 'mrmrssign', title: 'Couple Chair Sign',
        description: "Hangs on the back of couple's chairs"
    },
    {
        name: 'weddingrules', title: '',
        description: ""
    },
    {
        name: 'kaiasign', title: '',
        description: ""
    },
    {
        name: 'Centerpieces', title: '',
        description: ""
    },
    {
        name: 'demiDonut', title: '',
        description: ""
    },
    {
        name: 'SeatingArrangements', title: '',
        description: ""
    },
    {
        name: 'frankEleniWelcome', title: 'Welcome Sign',
        description: "3 Feet Wide x 2.5 Feet Tall with Stand"
    },
    {
        name: 'welcomeSetUp', title: 'Welcome Sign',
        description: "3 Feet Wide x 2.5 Feet Tall with Stand"
    },
    {
        name: 'eleniFrankArbor', title: 'Triangular Arbor',
        description: "9 Feet Tall x 8.5 Feet Wide with 4.5 foot long base. Includes eye hooks on the back for flowers."
    },
    {
        name: 'arborChairs', title: 'Triangular Arbor',
        description: "9 Feet Tall x 8.5 Feet Wide with 4.5 foot long base. Includes eye hooks on the back for flowers.",
        version: true
    },
    {
        name: 'ArborOutside', title: 'Triangular Arbor',
        description: "9 Feet Tall x 8.5 Feet Wide with 4.5 foot long base. Includes eye hooks on the back for flowers."
    },
    {
        name: 'donutAngleFull', title: 'Donut Wall',
        description: "6 Feet Tall x 3 Feet Wide x 2.5 Feet Deep. Holds between 32-96 donuts. Has 2 shelves and utensil holder. Comes with 'Donut Mind If I Do' sign."
    },
    {
        name: 'lightBulb', title: '',
        description: ""
    },   
    {
        name: 'champagneGlasses', title: 'Champagne Wall',
        description: "7 Feet Tall x 3 Feet Wide x 2 Feet Deep. Holds 60-120 champagne flutes. Comes with 'Cheers!' sign."
    },
    {
        name: 'takeAPicture', title: 'Picture Backdrop',
        description: "6.5 Feet Tall x 7 Feet Wide x 1.5 Feet Deep. Comes with 'Take A Picture It'll Last Longer' sign."
    },
    
]


// keys

export const Cloud = process.env.REACT_APP_CLOUD_NAME
export const Key = process.env.REACT_APP_API_KEY
export const Secret = process.env.REACT_APP_API_SECRET



